<template>
  <div id="app" class="bg-gray-100 p-8">
    <h1 class="text-3xl font-bold mb-4">Ranka Biran!</h1>
    <BeerList />
  </div>
</template>

<script>
import BeerList from '@/components/BeerList.vue';

export default {
  components: {
    BeerList,
  },
};
</script>

<style>
/* Add your styles here */
</style>
