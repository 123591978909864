<template>
  <div>
    <div v-if="!userId">
      <label for="userNameInput" class="text-xl font-semibold mb-2">Ange ditt namn:</label>
      <input
        id="userNameInput"
        type="text"
        v-model="userInput"
        class="w-full px-4 py-2 border rounded focus:outline-none focus:border-blue-500"
      />
      <button @click="saveUserName" class="mt-4 bg-blue-500 text-white px-4 py-2 rounded">Spara</button>
    </div>
    <div v-else>
      <h2 class="text-3xl font-bold mb-8"></h2>
      <ul class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <li
          v-for="beer in beerSort(beers)"
          :key="beer._id"
          class="mb-8 p-6 bg-white rounded shadow-md transition duration-300 transform hover:scale-105 cursor-pointer"
          @click="selectBeer(beer)"
        >
          <div>
            <span class="block text-xl font-semibold mb-2">{{ beer.name }} {{ beer.votes.length ? `(${beer.votes.length} röster)`  : null}}</span>
            <span class="block text-gray-600">Rating: {{ calculateAverageRating(beer.votes) }}</span>
            <div v-if="selectedBeer && selectedBeer._id === beer._id" class="mt-4" @click.stop>
              <label for="ratingSlider" class="block text-sm font-semibold mb-2">Betygsätt ölen</label>
              <input
                id="ratingSlider"
                type="range"
                v-model="selectedRating"
                min="0"
                max="10"
                step="0.1"
                class="w-full"
                @input.stop
                @click.stop
              />
              <div class="text-lg font-semibold mt-2">Du röstar: {{ selectedRating }}/10</div>
              <button @click.stop="voteForBeer(beer._id)" class="mt-4 bg-blue-500 text-white px-4 py-2 rounded">
                Vote
              </button>
            </div>
        </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      beers: [],
      userId: localStorage.getItem('userId') ?? null, 
      userInput: null,
      selectedBeer: null,
      selectedRating: 5, // Default rating
    };
  },
  mounted() {
    if (this.userId) this.fetchBeers();
  },
  methods: {
    saveUserName() {
      if (this.userInput.trim() !== '') {
        this.userId = this.userInput;
        localStorage.setItem('userId', this.userId);
        this.tempUserName = '';
        this.fetchBeers();
      }
    },
    async fetchBeers() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/api/beers`);
        const beers = await response.json();
        this.beers = beers;
      } catch (error) {
        console.error(error);
      }
    },
    selectBeer(beer) {
      // Toggle the selectedBeer when clicked
      this.selectedBeer = this.selectedBeer === beer ? null : beer;
      // Reset the selectedRating when a new beer is selected
      this.selectedRating = 5;
    },
    beerSort(beers) {
      return beers.slice().sort((a, b) => {
        const ratingA = this.calculateAverageRating(a.votes);
        const ratingB = this.calculateAverageRating(b.votes);

        // Sort in descending order based on average rating
        return ratingB - ratingA;
      });
    },
    async voteForBeer(beerId) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/api/beers/${beerId}/votes`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: this.userId,
            rating: this.selectedRating,
          }),
        });

        const updatedBeer = await response.json();
        const index = this.beers.findIndex((beer) => beer._id === updatedBeer._id);
        this.$set(this.beers, index, updatedBeer);

        // Reset the selectedBeer after voting
        this.selectedBeer = null;
        this.fetchBeers();
      } catch (error) {
        console.error(error);
      }
    },
    calculateAverageRating(votes) {
      if (!votes || votes.length === 0) {
        return 'N/A';
      }

      const totalRating = votes.reduce((sum, vote) => sum + vote.rating, 0);
      const averageRating = totalRating / votes.length;
      return averageRating.toFixed(1);
    },
  },
};
</script>
